<div class="container">
  <h2 class="section-title wow fadeInUp Michelia">Gallery</h2>

  <div class="row">
    <div class="col-12 centered">
      <h4 class="DensiaSans gallery-subtitle">Soft Glam Makeup</h4>
    </div>
  </div>
  
<div class="row">
    <div class="col-6 centered">
        <h4 class="DensiaSans gallery-subtitle underlined">Before</h4>
    </div>
    <div class="col-6 centered">
        <h4 class="DensiaSans gallery-subtitle underlined">After</h4>
    </div>
</div>
  <div class="row">
    <div class="col-6 centered">
        <lightgallery [settings]="settings" [onInit]="onInit">
            <a class="gallery-image gallery-item" href="assets/images/gallery/girl-before.jpg">
                <img class="gallery-thumbnail img-responsive" alt="img1" src="assets/images/gallery/girl-before.jpg" />
            </a>
        </lightgallery>
      </div>
      <div class="col-6 centered">
        <lightgallery [settings]="settings" [onInit]="onInit">
            <a class="gallery-image gallery-item" href="assets/images/gallery/girl-after.jpg">
                <img class="gallery-thumbnail img-responsive" alt="img1" src="assets/images/gallery/girl-after.jpg" />
            </a>
        </lightgallery>
      </div>
  </div>

  <!-- <div class="spacer-20"></div> -->
  <br>

  <div class="row">
    <div class="col-12 centered">
      <h4 class="DensiaSans gallery-subtitle gallery-subtitle-2">Eyebrow Wax & Tint</h4>
    </div>
  </div>
  
<div class="row">
    <div class="col-6 centered">
        <h4 class="DensiaSans gallery-subtitle underlined">Before</h4>
    </div>
    <div class="col-6 centered">
        <h4 class="DensiaSans gallery-subtitle underlined">After</h4>
    </div>
</div>
  <div class="row">
    <div class="col-6 centered">
        <lightgallery [settings]="settings" [onInit]="onInit">
            <a class="gallery-image gallery-item" href="assets/images/gallery/eye-before.jpg">
                <img class="gallery-thumbnail img-responsive" alt="img1" src="assets/images/gallery/eye-before.jpg" />
            </a>
        </lightgallery>
      </div>
      <div class="col-6 centered">
        <lightgallery [settings]="settings" [onInit]="onInit">
            <a class="gallery-image gallery-item" href="assets/images/gallery/eye-after.jpg">
                <img class="gallery-thumbnail img-responsive" alt="img1" src="assets/images/gallery/eye-after.jpg" />
            </a>
        </lightgallery>
      </div>
  </div>




</div>
<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp Michelia">Get In Touch</h2>

  <div class="spacer-30 desktop-only"></div>
  <div class="spacer-20 mobile-only"></div>


  <div class="row">
    <div class="col-md-4">
      <div class="row">
        <div class="contact-info desktop-only">
          <h3 class="wow fadeInUp DensiaSans paragraph">Let's talk about everything!</h3>
          <p class="wow fadeInUp DensiaSans paragraph desktop-only">Don't like forms? Contact me via email or social media.</p>
        </div>
        <div class="centered w-100 mobile-only">
          <h3 class="wow fadeInUp DensiaSans paragraph">Let's talk about everything!</h3>
        </div>
      </div>
      <div class="desktop-only">
        <br>
        <div class="row">
          <div class="col-md-2 icon-column">
            <i class="fi fi-rr-envelope contact-icon"></i>
          </div>
          <div class="col-md-10">
            <a class="DensiaSans paragraph contact-link" href="mailto:makeupwithgabriella@gmail.com?subject=Customer Enquiry">Makeupwithgabriella@gmail.com</a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 icon-column">
            <i class="fi fi-bs-phone-call contact-icon"></i>
          </div>
          <div class="col-md-10">
            <a class="DensiaSans paragraph contact-link" href="tel:+447919150608">07919150608</a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-2 icon-column">
            <i class="fi fi-brands-instagram contact-icon"></i>
          </div>
          <div class="col-md-10">
            <a class="DensiaSans paragraph contact-link" href="https://www.instagram.com/Makeup.BeautyWithGabriella">@Makeup.BeautyWithGabriella</a>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8">
      <!-- Contact Form -->
      <form id="contact-form" class="contact-form mt-6" [formGroup]="formData" *ngIf="formData" (ngSubmit)="onSubmit(formData.value)">
        <div class="messages"></div>

        <div class="row">
          <div class="column col-md-6">
            <!-- Name input -->
            <div class="form-group">
              <input
                type="text"
                class="form-control shadow-darkpink"
                name="InputName"
                id="InputName"
                placeholder="Your name"
                required="required"
                data-error="Name is required."
                formControlName="InputName"
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-6">
            <!-- Email input -->
            <div class="form-group">
              <input
                type="email"
                class="form-control shadow-darkpink"
                id="InputEmail"
                name="InputEmail"
                placeholder="Email address"
                required="required"
                data-error="Email is required."
                formControlName="InputEmail"
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Email input -->
            <div class="form-group">
              <input
                type="text"
                class="form-control shadow-darkpink"
                id="InputSubject"
                name="InputSubject"
                placeholder="Subject"
                required="required"
                data-error="Subject is required."
                formControlName="InputSubject"
              />
              <div class="help-block with-errors"></div>
            </div>
          </div>

          <div class="column col-md-12">
            <!-- Message textarea -->
            <div class="form-group">
              <textarea
                name="InputMessage"
                id="InputMessage"
                class="form-control shadow-darkpink"
                rows="5"
                placeholder="Message"
                required="required"
                data-error="Message is required."
                formControlName="InputMessage"
                maxLength="512"
              ></textarea>
              <div class="help-block with-errors"></div>
            </div>
          </div>
        </div>

        <button type="submit" name="submit" id="submit" value="Submit" class="btn btn-success submit-btn" [disabled]="!formData.valid">Send Message</button
        ><!-- Send Button -->
      </form>
      <!-- Contact Form end -->
    </div>
  </div>

  <div class="container mobile-only">
    <br>
    <p class="wow fadeInUp DensiaSans paragraph">Don't like forms? Contact me via email or social media.</p>
    <br>
    <div class="row">
      <div class="col-2 icon-column">
        <i class="fi fi-rr-envelope contact-icon"></i>
      </div>
      <div class="col-10">
        <a class="DensiaSans paragraph contact-link" href="mailto:makeupwithgabriella@gmail.com?subject=Customer Enquiry">Makeupwithgabriella@gmail.com</a>
      </div>
    </div>
    <div class="row">
      <div class="col-2 icon-column">
        <i class="fi fi-bs-phone-call contact-icon"></i>
      </div>
      <div class="col-10">
        <a class="DensiaSans paragraph contact-link" href="tel:+447919150608">07919150608</a>
      </div>
    </div>
    <div class="row">
      <div class="col-2 icon-column">
        <i class="fi fi-brands-instagram contact-icon"></i>
      </div>
      <div class="col-10">
        <a class="DensiaSans paragraph contact-link" href="https://www.instagram.com/Makeup.BeautyWithGabriella">@Makeup.BeautyWithGabriella</a>
      </div>
    </div>
  </div>



</div>

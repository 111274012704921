<div class="container">
  <!-- section title -->
  <h2 class="section-title fadeInUp Michelia">More about Me</h2>
  <div class="spacer-30"></div>

  <div class="row">

    <div class="row">
      <div class="col-md-3">
        <div class="text-center text-md-left">
          <!-- avatar image -->
          <!-- <img class="profile-pic" src="assets/images/profile-pic2.png" alt="Gabriella" style="border-radius:50%;"/> -->
          <div class="centered">
          <img class="profile-pic pic1 mobile-only" src="assets/images/profile-pic.png" alt="Gabriella" style="border-radius:50%;"/>
          <img class="profile-pic pic2 desktop-only" src="assets/images/profile-pic2.png" alt="Gabriella" style="border-radius:50%;"/>
            
        </div>
        </div>
        <div class="spacer-30 d-md-none d-lg-none"></div>
      </div>

    <div class="col-md-9">
      <div class="about-box rounded-edges data-background padding-30 text-center text-dark shadow-darkpink">
        <span *ngIf="!showMore" class="mobile-only">
          <p class="DensiaSans paragraph">
          
            I am an experienced waxer, specialising in facial waxing and tinting.
    
            I love sending my clients home with the best brows, and giving them an amazing experience.
    
            I always take the time to get to know my customers, and find out exactly what their beauty needs are...
            </p> 
        </span>

        <span *ngIf="showMore" id="moreText" class="mobile-only" >
          <p class="DensiaSans paragraph">
          
            I am an experienced waxer, specialising in facial waxing and tinting.
    
            I love sending my clients home with the best brows, and giving them an amazing experience.
    
            I always take the time to get to know my customers, and find out exactly what their beauty needs are.
            </p> 
          <br><br>
          <p class="DensiaSans paragraph">
            From a young age, I always had a love for all creative arts, but once I got my hands on a make up brush and an eyeshadow palette there was no going back. 
  My passion for make up started to take off by the time I was a teenager, after years of ‘borrowing’ my mum’s make up, I just knew that make up had to be in my future. 
  <br><br>
  Wanting to better my skills and knowledge, I decided to delve into the waxing world and learn about the art of facial waxing.
          </p>
  
          <p class="DensiaSans paragraph">Years later, and I have extensive knowledge, experience and I can talk to you about all your skincare problems, make up queries and waxing concerns under one roof. 
            <br><br>
            With my mum is my biggest inspiration and my number one fan, I wouldn’t be where I am today without her. Oh, and you’ll be glad to hear, that now she ‘borrows’ make up from me.
          </p>
        </span>

        <span  class="desktop-only">
          <p class="DensiaSans paragraph">Hi, I'm Gabriella, and I'm a Brow & Beauty Expert, and a Makeup Artist, based in St. Albans, Hertfordshire.
            Having worked and trained with big brands such as Benefit Cosmetics, and Space NK, I am extremely knowledgable
            about skincare, waxing and makeup products.
            <br><br>
        </p>
        
          <p class="DensiaSans paragraph">
          
            I am an experienced waxer, specialising in facial waxing and tinting.
    
            I love sending my clients home with the best brows, and giving them an amazing experience.
    
            I always take the time to get to know my customers, and find out exactly what their beauty needs are.
            </p> 
          <p class="DensiaSans paragraph">
            From a young age, I always had a love for all creative arts, but once I got my hands on a make up brush and an eyeshadow palette there was no going back. 
  My passion for make up started to take off by the time I was a teenager, after years of ‘borrowing’ my mum’s make up, I just knew that make up had to be in my future. 
  <br><br>
  Wanting to better my skills and knowledge, I decided to delve into the waxing world and learn about the art of facial waxing.
          </p>
  
          <p class="DensiaSans paragraph">Years later, and I have extensive knowledge, experience and I can talk to you about all your skincare problems, make up queries and waxing concerns under one roof. 
            With my mum is my biggest inspiration and my number one fan, I wouldn’t be where I am today without her. Oh, and you’ll be glad to hear, that now she ‘borrows’ make up from me.
          </p>
        </span>
          <!-- <div class="align-middle"> -->
            <a class="seeMoreButton mobile-only" (click)="toggleText()">{{ buttonText }}</a>
      </div>
    </div>
  </div>


  <div class="spacer-20"></div>
</div>



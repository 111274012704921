<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Here's what happens next</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-12">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-white bg-blue">
        <!-- <img src="assets/images/service-1.svg" alt="UI/UX design" /> -->
        <h3 class="mb-3 mt-0">Consultation</h3>
        <p class="mb-0">Before getting into the waxing, we have a consultation and speak about all your brow troubles. We map out exactly where we are going to wax, so we are both on the same page.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
  </div>
  <!-- row end -->
  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-12">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center shadow-yellow bg-yellow">
        <!-- <img src="assets/images/service-1.svg" alt="UI/UX design" /> -->
        <h3 class="mb-3 mt-0">Choose a service</h3>
        <p class="mb-0">Some information here about choosing a service</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
  </div>
  <!-- row end -->
  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-12">
      <!-- service box -->
      <div class="service-box rounded data-background padding-30 text-center text-light shadow-pink bg-pink">
        <!-- <img src="assets/images/service-1.svg" alt="UI/UX design" /> -->
        <h3 class="mb-3 mt-0">Box number 3</h3>
        <p class="mb-0">Some information about what goes into box number 3</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
  </div>
  <!-- row end -->
  <div class="spacer-60"></div>

  <!-- <div class="row">
     <div class="col-md-3 col-sm-6">
      fact item
      <div class="fact-item">
        <span class="icon icon-fire"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">198</em></h3>
          <p class="mb-0">Projects completed</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div> 

    <div class="col-md-4 col-sm-12">
       fact item
      <div class="fact-item">
        <span class="icon icon-briefcase"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">100+</em></h3>
          <p class="mb-0">Items in my kit</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-4 col-sm-12">
      fact item 
      <div class="fact-item">
        <span class="icon icon-people"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">15</em></h3>
          <p class="mb-0">Satisfied clients</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-12">
      fact item 
      <div class="fact-item">
        <span class="icon icon-graduation"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">3</em></h3>
          <p class="mb-0">Years of experience</p>
        </div>
      </div>
    </div>
  </div> -->
</div>

<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp Michelia">Makeup treatments</h2>
  <br>
  <span class="DensiaSans paragraph desktop-only">When you book a lesson with Gabriella, you get the opportunity to learn about the basics and fundamentals about makeup & skincare products. I'll be here to answer all of your questions, address your concerns, and provide guidance to help you understand your skin. I offer cluster lashes rather than strip lashes, as it means I can customise the lashes to sit comfortably in any eye shape.
    <br><br>
    I pride myself in using high end, long lasting products, from brands such as: Charlotte Tilbury, Nars, Benefit Cosmetics, Mac, Hourglass, Laura Mercier, Shiseido, and many more.
    I listen to my clients wants and needs before starting any makeup treatment, meaning the client and myself are on the same page at all times.
  </span>

  <span *ngIf="!showMore" class="DensiaSans paragraph mobile-only">When you book a lesson with Gabriella, you get the opportunity to learn about the basics and fundamentals about makeup & skincare products...
    <a class="seeMoreButton mobile-only" (click)="toggleText()">{{ buttonText }}</a>
    </span>
    <span *ngIf="showMore" class="DensiaSans paragraph mobile-only">
    When you book a lesson with Gabriella, you get the opportunity to learn about the basics and fundamentals about makeup & skincare products.
    I'll be here to answer all of your questions, address your concerns, and provide guidance to help you understand your skin.
    <br><br>
    I offer cluster lashes rather than strip lashes, as it means I can customise the lashes to sit comfortably in any eye shape.
    I pride myself in using high end, long lasting products, from brands such as: Charlotte Tilbury, Nars, Benefit Cosmetics, Mac, Hourglass, Laura Mercier, Shiseido, and many more.
    <br><br>
    I listen to my clients wants and needs before starting any makeup treatment, meaning the client and myself are on the same page at all times.
    <a class="seeMoreButton mobile-only" (click)="toggleText()">{{ buttonText }}</a>
  </span>

  <div class="spacer-30"></div>

    <div class="container">
      <div class="row">


        <div class="col-xl col-lg-6 col-md-12 pricelist-section desktop-only">
          <h4 class="DensiaSans pricelist-heading">Makeup</h4>
        <div class="solid-line-break"></div>
          <div class="row">
            <div class="col">
              <p class="DensiaSans pricelist">Makeup (Under 14s)</p>
              <p class="DensiaSans pricelist">Natural Makeup</p>
              <p class="DensiaSans pricelist">Soft Glam </p>
              <p class="DensiaSans pricelist">Full Glam</p>
              <p class="DensiaSans pricelist">Trials</p>
            </div>
            <div class="col">
              <p class="DensiaSans pricelist price">£30</p>
              <p class="DensiaSans pricelist price">£45</p>
              <p class="DensiaSans pricelist price">£55</p>
              <p class="DensiaSans pricelist price">£65</p>
              <p class="DensiaSans pricelist price">£50pp</p>
            </div>
          </div>
        </div>

        <div class="col-xl col-lg-6 col-md-12 pricelist-section mobile-only">
          <h4 class="DensiaSans pricelist-heading">Makeup</h4>
        <div class="solid-line-break"></div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Makeup (Under 14s)</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£30</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Natural Makeup</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£45</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Soft Glam </p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£55</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Full Glam</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£65</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Trials</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£50pp</p>
          </div>
        </div>
        </div>

        <div class="col-xl col-lg-6 col-md-12 pricelist-section desktop-only">
          <h4 class="DensiaSans pricelist-heading">Lessons</h4>
        <div class="solid-line-break"></div>
          <div class="row">
            <div class="col">
              <p class="DensiaSans pricelist">30 minute lesson</p>
              <p class="DensiaSans pricelist">60 minute lesson</p>
              <h4 class="DensiaSans pricelist-heading">Lashes</h4>
              <div class="solid-line-break"></div>
              <p class="DensiaSans pricelist">Cluster lash - half set</p>
              <p class="DensiaSans pricelist">Cluster lash - full set</p>
            </div>
            <div class="col">
              <p class="DensiaSans pricelist price">£25</p>
              <p class="DensiaSans pricelist price">£50</p>
              <br><br>
              <p class="DensiaSans pricelist price">£15</p>
              <p class="DensiaSans pricelist price">£25</p>
            </div>
          </div>
        </div>

        <div class="col-xl col-lg-6 col-md-12 pricelist-section mobile-only">
          <h4 class="DensiaSans pricelist-heading">Lessons</h4>
        <div class="solid-line-break"></div>
          <div class="row">
            <div class="col">
              <p class="DensiaSans pricelist">30 minute lesson</p>
              <p class="DensiaSans pricelist">60 minute lesson</p>
            </div>
            <div class="col">
              <p class="DensiaSans pricelist price">£25</p>
              <p class="DensiaSans pricelist price">£50</p>
            </div>
          </div>
        </div>

        <div class="col-xl col-lg-6 col-md-12 pricelist-section mobile-only">
          <h4 class="DensiaSans pricelist-heading">Lashes</h4>
          <div class="solid-line-break"></div>
          <div class="row">
            <div class="col">
              <p class="DensiaSans pricelist">Cluster lash - half set</p>
            </div>
            <div class="col">
              <p class="DensiaSans pricelist price">£15</p>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="DensiaSans pricelist">Cluster lash - full set</p>
            </div>
            <div class="col">
              <p class="DensiaSans pricelist price">£25</p>
            </div>
          </div>
        </div>

        <div class="col-xl col-lg-6 col-md-12 pricelist-section">
          <h4 class="DensiaSans pricelist-heading">Weddings</h4>
        <div class="solid-line-break"></div>
          <div class="row">
            <div class="col">
              <p class="DensiaSans pricelist">Bridesmaids</p>
              <p class="DensiaSans pricelist">Mother of the Bride/Groom</p>
            </div>
            <div class="col">
              <p class="DensiaSans pricelist price">£90</p>
              <p class="DensiaSans pricelist price">£90</p>
              <div class="xs-mobile-only">
                <br>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <p class="DensiaSans pricelist">Bridal</p>
            </div>
            <div class="col">
              <p class="DensiaSans pricelist price">£150</p>
            </div>
          </div>
        </div>
      </div>
    </div>


<div class="desktop-only hidden">
  <div class="row">
    <div class="col-xl-3 col-lg-12">
      <div class="service-box rounded data-background padding-servicebox text-center text-dark shadow-darkpink bg-white">
        <!-- <img src="assets/images/service-1.svg" alt="UI/UX design" /> -->
        <h3 class="DensiaSans mb-3 mt-0 process-box-text">1</h3>
        <p class="mb-0 DensiaSans paragraph process-box-text">During the consultation process for a make up treatment, Gabriella assesses your skin type to ensure the chosen products complement
          your skin. Additionally, we can explore lash options, which can enhance the overall makeup look.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-xl-1 col-lg-12 vertical-center">
      <a class="stage-arrow-circle">
          <i class="fas fa-arrow-right fa-arrow-down center-arrow"></i>
      </a>
      <div class="spacer-20 mobile-only"></div>
    </div>

    <div class="col-xl-3 col-lg-12">
      <div class="service-box rounded data-background padding-servicebox text-center text-dark shadow-darkpink bg-white">
        <h3 class="DensiaSans mb-3 mt-0 process-box-text">2</h3>
        <p class="mb-0 DensiaSans paragraph process-box-text">Gabriella takes active steps to demonstrate and involve the customer throughout the makeup treatment, ensuring that both parties have a shared understanding and agreement on the service.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-xl-1 col-lg-12 vertical-center">
      <a class="stage-arrow-circle">
          <i class="fas fa-arrow-right fa-arrow-down center-arrow"></i>
      </a>
      <div class="spacer-20 mobile-only"></div>
    </div>

    <div class="col-xl-3 col-lg-12">
      <div class="service-box rounded data-background padding-servicebox text-center text-dark shadow-darkpink bg-white">
        <!-- <img src="assets/images/service-1.svg" alt="UI/UX design" /> -->
        <h3 class="DensiaSans mb-3 mt-0 process-box-text">3</h3>
        <p class="mb-0 DensiaSans paragraph process-box-text">Following the completion of your makeup treatment or lesson, Gabriella will offer valuable after-care advice and discuss upcoming dates for your next event or lesson, ensuring a seamless experience.</p>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>
  </div>
</div>
</div>
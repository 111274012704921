import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
  animations: [
    trigger('rotate', [
      state('right', style({ transform: 'rotate(0deg)' })),
      state('down', style({ transform: 'rotate(90deg)' })),
      transition('right <=> down', animate('0.3s ease')),
    ]),
  ],
})
export class FaqComponent implements OnInit {
  arrowState: 'right' | 'down' = 'right';
  
  constructor() {}

  ngOnInit(): void {}

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  rotateArrow() {
    this.arrowState = this.arrowState === 'right' ? 'down' : 'right';
  }
}

import { BrowserModule } from '@angular/platform-browser';
import { ClickOutsideModule } from 'ng-click-outside';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutComponent } from './theme/layout/layout.component';
import { LoaderModule } from './components/loader/loader.module';
import { SharedModule } from './components/shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule } from '@angular/common/http';
import { ContactService } from './contact.service';
import { ReactiveFormsModule  } from '@angular/forms'; 
import { LightgalleryModule } from 'lightgallery/angular';

@NgModule({
  declarations: [AppComponent, LayoutComponent],
  imports: [BrowserAnimationsModule, LightgalleryModule, BrowserModule, AppRoutingModule, LoaderModule, ClickOutsideModule, SharedModule, NgbModule, HttpClientModule, ReactiveFormsModule],
  providers: [ContactService],
  bootstrap: [AppComponent]
})
export class AppModule {}

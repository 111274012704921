import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ContactService } from 'src/app/contact.service';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  constructor(private builder: FormBuilder, private contact: ContactService) 
  {  }

  formData = this.builder.group({
    InputName: ['', Validators.required],
    InputEmail: ['', [Validators.required, Validators.email]],
    InputSubject: ['', Validators.required],
    InputMessage: ['', Validators.required],
  });


  ngOnInit() {

  }

  onSubmit(FormData) {
    console.log(FormData)
    this.contact.PostMessage(FormData)
    .subscribe(response => {
    alert("Thank you for your message, we'll be in touch soon!");
    this.formData.reset();
    console.log(response)
    }, error => {
    console.warn(error.responseText)
    console.log({ error })
    alert("Sorry, there's been an error! We're working hard to get this fixed. In the mean time, feel free to reach out by clicking on the email button.");
    })
  }
}

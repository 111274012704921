import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LightgalleryComponent } from 'lightgallery/angular';
import { LightgalleryModule } from 'lightgallery/angular';
import lgZoom from 'lightgallery/plugins/zoom';
import { InitDetail } from 'lightgallery/lg-events';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class GalleryComponent implements OnInit {

  
  settings = {
    counter: false,
    plugins: [lgZoom],
};
  lightGallery: any;
  needRefresh: false;
  constructor() {}

  ngAfterViewChecked(): void {
    if (this.needRefresh) {
      this.lightGallery.refresh();
      this.needRefresh = false;
    }
  }

  ngOnInit(): void {}

  onInit = (detail: InitDetail): void => {
    this.lightGallery = detail.instance;
  };
}

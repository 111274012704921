<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp Michelia">Beauty treatments</h2>
  <br>
  <div class="align-middle">
    <span id="moreText" class="DensiaSans paragraph desktop-only">
      I use a coconut gel based wax, which is gentle, and leaves skin feeling smooth, and soft.
      Facial waxing is beneficial for your skin because it will allow for an easier makeup application.
      Eyelash, and eyebrow tinting is a great way to naturally deepen and enhance the eye without the need for makeup.
      It works by latching onto smaller, lighter hairs, which aren't visible.
      <br><br>
      I custom blend all my tints, giving me the opportunity to find the perfect shade for everyone.
      Eye masks are the perfect add-on, its a luxurious cooling mask that sits under your eyes, and makes you feel relaxed and comfortable.
  </span>
  <span *ngIf="showMore" id="moreText" class="DensiaSans paragraph mobile-only">
    I use a coconut gel based wax, which is gentle, and leaves skin feeling smooth, and soft.
    Facial waxing is beneficial for your skin because it will allow for an easier makeup application.
    Eyelash, and eyebrow tinting is a great way to naturally deepen and enhance the eye without the need for makeup.
    <br><br>
    It works by latching onto smaller, lighter hairs, which aren't visible.
    I custom blend all my tints, giving me the opportunity to find the perfect shade for everyone.
    Eye masks are the perfect add-on, its a luxurious cooling mask that sits under your eyes, and makes you feel relaxed and comfortable.
    <a class="seeMoreButton mobile-only" (click)="toggleText()">{{ buttonText }}</a>
</span>
  </div>
  <span *ngIf="!showMore" class="DensiaSans paragraph lessText mobile-only">I use a coconut gel based wax, which is gentle, and leaves skin feeling smooth, and soft...
    <a class="seeMoreButton mobile-only" (click)="toggleText()">{{ buttonText }}</a>
  </span>
  <!-- <div class="align-middle"> -->
  <!-- </div> -->


  <div class="spacer-30"></div>



  <div class="spacer-20"></div>

  <div class="container">
    <div class="row">
      <div class="col-xl col-lg-6 col-md-12 pricelist-section">
        <h4 class="DensiaSans pricelist-heading">Waxing</h4>
        <div class="solid-line-break"></div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Eyebrow wax</p>
            <p class="DensiaSans pricelist">Lip wax</p>
            <p class="DensiaSans pricelist">Side wax</p>
            <p class="DensiaSans pricelist">Chin wax</p>
            <p class="DensiaSans pricelist">Neck wax</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£12</p>
            <p class="DensiaSans pricelist price">£6</p>
            <p class="DensiaSans pricelist price">£9</p>
            <p class="DensiaSans pricelist price">£9</p>
            <p class="DensiaSans pricelist price">£9</p>
          </div>
        </div>
        <div class="spacer-30"></div>
      </div>

      <!-- <div class="col-xl col-lg-6 col-md-12 pricelist-section desktop-only">
        <h4 class="DensiaSans pricelist-heading">Tinting</h4>
        <div class="solid-line-break"></div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Eyebrow tint</p>
            <p class="DensiaSans pricelist">Eyelash tint</p>
            
            <h4 class="DensiaSans pricelist-heading">Add ons</h4>
        <div class="solid-line-break"></div>

            <p class="DensiaSans pricelist">Eyebrow Tweeze</p>
            <p class="DensiaSans pricelist">Eyemask</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£8</p>
            <p class="DensiaSans pricelist price">£10</p>
            <br><br>
            <p class="DensiaSans pricelist price">£8</p>
            <p class="DensiaSans pricelist price">£2.50</p>
          </div>
        </div>
      </div> -->

      <div class="col-xl col-lg-6 col-md-12 pricelist-section">
        <h4 class="DensiaSans pricelist-heading">Tinting</h4>
        <div class="solid-line-break"></div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Eyebrow tint</p>
            <p class="DensiaSans pricelist">Eyelash tint</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£8</p>
            <p class="DensiaSans pricelist price">£10</p>
          </div>
        </div>
        <div class="spacer-30 mobile-only"></div>
        <h4 class="DensiaSans pricelist-heading">Add ons</h4>
        <div class="solid-line-break"></div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Eyebrow Tweeze</p>
            <p class="DensiaSans pricelist">Eyemask</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£8</p>
            <p class="DensiaSans pricelist price">£3</p>
          </div>
        </div>
        <div class="spacer-30"></div>
      </div>



      <div class="col-xl col-lg-6 col-md-12 pricelist-section">
        <h4 class="DensiaSans pricelist-heading">Bundles</h4>
        
        <div class="solid-line-break"></div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Eyebrow wax & tint</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£17</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Eyebrow & Eyelash tint</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£15</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Lip & Chin wax</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£12</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <p class="DensiaSans pricelist">Lip, Chin, & Side wax</p>
          </div>
          <div class="col">
            <p class="DensiaSans pricelist price">£20</p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="desktop-only hidden">
    <p class="DensiaSans paragraph">
      Here is what to expect when booking a beauty treatment with Gabriella.
    </p>
    <br>
    
      <div class="row">
        <div class="col-xl-3 col-lg-12">
          <div class="service-box rounded data-background padding-servicebox text-center text-dark shadow-darkpink bg-white">
            <h3 class="DensiaSans mb-3 mt-0 process-box-text">1</h3>
            <p class="DensiaSans paragraph process-box-text">During the consultation process for a beauty treatment, Gabriella assesses your skin type and concerns to ensure a safe and tailored experience.
            </p>
            <br><br>
            <div class="half-br"></div>
          </div>
          <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>
    
        <div class="col-xl-1 col-lg-12 vertical-center" >
          <a class="stage-arrow-circle">
            <i class="fas fa-arrow-right  center-arrow"></i>
          </a>
        </div>
    
        <div class="col-xl-3 col-lg-12">
          <div class="service-box rounded data-background padding-servicebox text-center text-dark shadow-darkpink bg-white">
            <h3 class="DensiaSans mb-3 mt-0 process-box-text">2</h3>
            <p class="DensiaSans paragraph process-box-text">Gabriella takes active steps to demonstrate and involve the customer throughout the treatment, ensuring that both parties have a shared understanding and agreement on the service.
            </p>
          </div>
          <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>
    
        <div class="col-xl-1 col-lg-12 vertical-center">
          <a class="stage-arrow-circle">
              <i class="fas fa-arrow-right fa-arrow-down center-arrow"></i>
          </a>
          <div class="spacer-20 mobile-only"></div>
        </div>
        
        <div class="col-xl-3 col-md-12">
          <div class="service-box rounded data-background padding-servicebox text-center text-dark shadow-darkpink bg-white">
            <h3 class="DensiaSans mb-3 mt-0 process-box-text">3</h3>
            <p class="DensiaSans paragraph mb-0 process-box-text">After the beauty treatment has been completed, Gabriella  will assist you in booking your next appointment and provide after-care advice to maintain the benefits of the treatment.</p>
          </div>
          <div class="spacer-30 d-md-none d-lg-none"></div>
        </div>
      </div>
      <div class="spacer-20"></div>
  </div>
</div>
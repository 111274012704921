import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-continued',
  templateUrl: './about.continued.component.html',
  styleUrls: ['./about.continued.component.scss']
})
export class AboutContinuedComponent implements OnInit {
  showMore = false;
  buttonText = 'Read more';
  constructor() {}

  ngOnInit(): void {}

  toggleText() {
    this.showMore = !this.showMore;
    this.buttonText = this.showMore ? 'Read less' : 'Read more';
  }
}

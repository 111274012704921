import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class ContactService {
  private endpoint = 'https://mailing-agent-nwdxoz6eyq-ew.a.run.app/api/sendEmail'

  constructor(private http: HttpClient) { }

  PostMessage(input: any) {
    return this.http.post(this.endpoint, input, { responseType: 'text'}).pipe(
      map(
        (response) => { return response; },
        (error: any) => { return error; }
      )
    )
  }
}

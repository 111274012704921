import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-makeup',
  templateUrl: './makeup.component.html',
  styleUrls: ['./makeup.component.scss']
})
export class MakeupComponent implements OnInit {
  showMore = false;
  buttonText = 'Read more';
  constructor() {}

  ngOnInit(): void {}

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }

  toggleText() {
    this.showMore = !this.showMore;
    this.buttonText = this.showMore ? 'Read less' : 'Read more';
  }
}

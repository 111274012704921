
  <!-- intro -->
  <div class="intro">

    <img src="assets/images/logo-updated-transparent.png" alt="Site logo" class="mb-4 site-logo"/> 
    <img src="assets/images/mobile-logo.png" alt="Site logo" class="mb-4 mobile-logo"/> 

  </div>

  <!-- scroll down mouse wheel -->
  <div class="scroll-down" [ngClass]="{ light: themeType === 'light' }">
    <a href="javascript:" class="mouse-wrapper" (click)="scrollTo('about')">
      <span class="scroll-down-text menu-item">Scroll Down</span>
      <span class="mouse menu-icon">
        <span class="wheel menu-icon"></span>
      </span>
    </a>
  </div>

  <!-- parallax layers -->
  <div id="scene" class="parallax" data-relative-input="true">

  </div>

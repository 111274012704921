import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { LightboxModule } from 'ngx-lightbox';
import { NgImageSliderModule } from 'ng-image-slider';
// import { IvyCarouselModule } from 'angular-responsive-carousel';

import { HomeComponent } from './home/home.component';
import { AboutComponent } from './about/about.component';
import { AboutContinuedComponent } from './aboutContinued/about.continued.component';

import { InformationComponent } from './information/information.component';
import { BeautyComponent } from './beauty/beauty.component';
import { FaqComponent } from './faq/faq.component';
import { MakeupComponent } from './makeup/makeup.component';


import { ExperienceComponent } from './experience/experience.component';
import { BlogComponent } from './blog/blog.component';
import { ContactComponent } from './contact/contact.component';
import { PricingComponent } from './pricing/pricing.component';

import { UiModalComponent } from './ui-modal/ui-modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule } from '@angular/forms';
import { GalleryComponent } from './gallery/gallery.component';
import { LightgalleryModule } from 'lightgallery/angular';

@NgModule({
  declarations: [
    HomeComponent,
    AboutComponent,
    AboutContinuedComponent,
    InformationComponent,
    BeautyComponent,
    MakeupComponent,
    GalleryComponent,
    FaqComponent,
    ExperienceComponent,
    ContactComponent,
    PricingComponent,
    UiModalComponent
  ],
  exports: [
    HomeComponent,
    AboutComponent,
    AboutContinuedComponent,
    InformationComponent,
    BeautyComponent,
    MakeupComponent,
    GalleryComponent,
    FaqComponent,
    ExperienceComponent,
    ContactComponent,
    PricingComponent,
    NgbModule
  ],
  imports: [CommonModule, NgImageSliderModule, NgbModule, ReactiveFormsModule, LightgalleryModule]
})
export class SectionsModule {}

<div class="container mobile-only">
  <!-- section title -->
  <h2 class="section-title fadeInUp Michelia">A little bit about Me</h2>

  <div class="spacer-30"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left">
        <!-- avatar image -->
        <!-- <img class="profile-pic" src="assets/images/profile-pic.png" alt="Gabriella" style="border-radius:50%;"/> -->
        <img class="profile-pic" src="assets/images/profile-pic2.png" alt="Gabriella" style="border-radius:50%;"/>

      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9">
      <div class="about-box rounded-edges data-background padding-30 text-center text-dark shadow-darkpink">
        <p class="DensiaSans paragraph">Hi, I'm Gabriella, and I'm a Brow & Beauty Expert, and a Makeup Artist, based in St. Albans, Hertfordshire.
          Having worked and trained with big brands such as Benefit Cosmetics, and Space NK, I am extremely knowledgable
          about skincare, waxing and makeup products.</p>
        
      </div>


    </div>
  </div>
  <div class="spacer-20"></div>
  <!-- row end -->
</div>


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoaderComponent } from './loader.component';
import { ReactiveFormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';

@NgModule({
  declarations: [LoaderComponent],
  exports: [LoaderComponent],
  imports: [CommonModule, ReactiveFormsModule, LightgalleryModule]
})
export class LoaderModule {}

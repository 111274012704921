<header
  *ngIf="this.themeConfig.theme !== 'horizontal'"
  class="{{ openClass }}"
  [ngClass]="mobileHeaderClass"
  (clickOutside)="openClass = ''"
>
  <div class="container">
    <!-- menu icon -->
    <div class="menu-icon d-inline-flex mr-4">
      <button (click)="openClass = openClass === 'open' ? '' : 'open'">
        <span></span>
      </button>
    </div>
    <!-- logo image -->
    <div class="site-logo">
      <a routerLink="/">
        <!-- <img [src]="this.themeConfig.themeType === 'light' ? 'assets/images/logo-dark.svg' : 'assets/images/logo.svg'" alt="Bolby" /> -->
      </a>
    </div>
  </div>
</header>

<header *ngIf="this.themeConfig.theme === 'horizontal'" [ngClass]="desktopHeaderClass" (window:resize)="onResize($event)">
  <div class="container">
    <nav class="navbar navbar-expand-lg" [ngClass]="horizontalNavClass">
      <a class="navbar-brand" routerLink="/">
        <img [src]="desktopLogo" alt="Bolby" />
      </a>
      <button
        (click)="collapsedCardToggle()"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
        class="navbar-toggler"
        data-target="#navbarNavDropdown"
        data-toggle="collapse"
        type="button"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse" id="navbarNavDropdown" [@collapsedCard]="collapsedCard">
        <ul class="navbar-nav ml-auto scrollspy">
          <li class="nav-item" [ngClass]="{ active: currentSection === 'home' }" (click)="scrollTo('home')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'home' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-home"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Home</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'about' }" (click)="scrollTo('about-continued')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'about' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-user-following"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">About</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'beauty' }" (click)="scrollTo('beauty')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'beauty' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-briefcase"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Beauty</span>
            </a>
          </li>
          <li class="nav-item" [ngClass]="{ active: currentSection === 'contact' }" (click)="scrollTo('contact')">
            <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'contact' }">
              <i *ngIf="this.themeConfig.theme !== 'horizontal'" class="icon-bubbles"></i>
              <span *ngIf="this.themeConfig.theme !== 'collapsed'">Contact</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<header
  *ngIf="this.themeConfig.theme !== 'horizontal'"
  class="d-flex align-items-start flex-column {{ openClass }}"
  [ngClass]="desktopHeaderClass"
>
  <!-- logo image -->
  <div class="site-logo">
    <a routerLink="/">
      <img [src]="desktopLogo" alt="Bolby" />
    </a>
  </div>

  <!-- main menu -->
  <!-- TODO - FIX ME -->
  <nav> 
    <ul class="vertical-menu scrollspy">
      <li [ngClass]="{ active: currentSection === 'home' }" (click)="scrollTo('home')">
        <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'home' }"
          ><i class="fi fi-rs-house-chimney menu-icon medium-icon"></i>
          <span class="menu-item" *ngIf="this.themeConfig.theme !== 'collapsed'">Home</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentSection === 'beauty' }" (click)="scrollTo('beauty')">
        <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'beauty' }">
          <i class="fi fi-rr-makeup-brush menu-icon medium-icon"></i>
            <!-- <img class="menu-icon medium-icon" src="assets/icons/navbar/beauty-icon.png"> -->
          <span class="menu-item" *ngIf="this.themeConfig.theme !== 'collapsed'">Beauty</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentSection === 'makeup' }" (click)="scrollTo('makeup')">
        <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'makeup' }"
          ><i class="fi fi-rs-lipstick menu-icon medium-icon"></i>
          <span class="menu-item" *ngIf="this.themeConfig.theme !== 'collapsed'">Makeup</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentSection === 'gallery' }" (click)="scrollTo('gallery')">
        <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'gallery' }"
          ><i class="fi fi-rr-picture menu-icon medium-icon"></i>
          <span class="menu-item" *ngIf="this.themeConfig.theme !== 'collapsed'">Gallery</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentSection === 'about' }" (click)="scrollTo('about-continued')">
        <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'about' }"
          ><i class="fi fi-rr-user menu-icon medium-icon"></i>
          <span class="menu-item" *ngIf="this.themeConfig.theme !== 'collapsed'">About</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentSection === 'faq' }" (click)="scrollTo('faq')">
        <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'faq' }"
          ><i class="fi fi-rr-interrogation menu-icon medium-icon"></i>
          <span class="menu-item" *ngIf="this.themeConfig.theme !== 'collapsed'">FAQ</span>
        </a>
      </li>
      <li [ngClass]="{ active: currentSection === 'contact' }" (click)="scrollTo('contact')">
        <a href="javascript:" class="nav-link" [ngClass]="{ active: currentSection === 'contact' }"
          ><i class="fi fi-rr-envelope-open menu-icon medium-icon"></i>
          <span class="menu-item" *ngIf="this.themeConfig.theme !== 'collapsed'">Contact</span>
        </a>
      </li>
    </ul>
  </nav>

  <!-- site footer -->
  <div class="footer">
    <!-- copyright text -->
    <!-- <span class="copyright">© 2020 Bolby Template.</span> -->
  </div>
</header>
<!-- main layout -->
<main class="{{ openClass }}" [ngClass]="contentClass">
  <router-outlet></router-outlet>

  <!-- Go to top button -->
  <!-- <a href="javascript:" id="return-to-top hidden" (click)="scrollTo('home')"><i class="fas fa-arrow-up"></i></a> -->
</main>

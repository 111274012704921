import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-beauty',
  templateUrl: './beauty.component.html',
  styleUrls: ['./beauty.component.scss']
})
export class BeautyComponent implements OnInit {    
  showMore = false;
  buttonText = 'Read more';

  constructor() {}

  ngOnInit(): void {}

  scrollTo(section) {
    const sectionHtml = document.querySelector('#' + section);
    if (sectionHtml !== null) {
      sectionHtml.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    }
  }


  toggleText() {
    this.showMore = !this.showMore;
    this.buttonText = this.showMore ? 'Read less' : 'Read more';
  }
}

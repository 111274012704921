import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScrollSpyDirective } from '../scroll-spy/scroll-spy.directive';
import { SectionsModule } from '../sections/sections.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LightgalleryModule } from 'lightgallery/angular';

@NgModule({
  declarations: [ScrollSpyDirective],
  imports: [CommonModule, SectionsModule, ReactiveFormsModule, LightgalleryModule],
  exports: [ScrollSpyDirective, SectionsModule],
  providers: [ScrollSpyDirective]
})
export class SharedModule {}

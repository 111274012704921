<div class="container">
  <h3 class="section-title wow fadeInUp Michelia">FAQ's</h3>

<p class="DensiaSans paragraph">
  Click on a question to see the answer.
</p>


<div class="centered">
  <h2 class="faq-heading Michelia">Beauty Questions</h2>
</div>
<div class="solid-line-break"></div>
<div ngbAccordion>
  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        
        <h4 class="DensiaSans question">
          <!-- <div class="arrow-container">
            <div [@rotate]="arrowState" class="arrow">&#9658;</div>
          </div> -->
          What are the benefits of waxing?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="
          ">
            It makes hair thinner, and therefore reduces growth over time. Since the hair is removed from the root,
the effect is longer lasting and more presise compared to plucking or threading.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          <!-- <div class="arrow-container">
            <div [@rotate]="arrowState" class="arrow">&#9658;</div>
          </div> -->
          How frequently should I get waxing & tinting done?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            The average schedule for tinting and waxing is 3-5 weeks, but this can vary.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          <!-- <div class="arrow-container">
            <div [@rotate]="arrowState" class="arrow">&#9658;</div>
          </div> -->
            How old do I need to be to get a facial wax?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            You should be at least 16 years of age to receive a facial wax.
Anyone under 18 will require a parent or guardian present during the service.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          <!-- <div class="arrow-container">
            <div [@rotate]="arrowState" class="arrow">&#9658;</div>
          </div> -->
          Should I get facial waxing if I have sensitive skin?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            If you feel that certain areas of your skin are feeling sesitive or inflamed, we can work around those areas.
Should you have any concerns, you can contact me to discuss this in more detail.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          <!-- <div class="arrow-container">
            <div [@rotate]="arrowState" class="arrow">&#9658;</div>
          </div> -->
          What type of wax is used?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            I use a gel coconut wax, which leaves the skin feeling really soft and silky.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          Do I need a patch test before tinting?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            This is at your discretion. If you are concerned, I would recommend booking a patch test and postponing the appointment by at least 48 hours.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          What circumstances am I not eligible for a beauty treatment?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            If you have recently had fillers or botox, you won't be able to receive beauty treatments for 4 weeks.
If you've just had a facial, please ensure that there is a 24 hour gap before your next waxing appointment.
<br>
I am unable to provide waxing services if you are currently taking medication that affects the skin, such as accutane.
If you are pregnant, please make sure you are past your first trimester before booking an appiontment. 
          </p>
        </ng-template>
      </div>
    </div>
  </div>

</div>
<br>
<div class="centered">
  <h2 class="faq-heading Michelia">Makeup Questions</h2>
</div>
<div class="solid-line-break"></div>
<div ngbAccordion>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          Can I book bridal make up without a trial?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
           Bridal make up is very personal, and therefore, I am unable to provide briadal makeup services without a trial. 
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          Can you travel to my wedding venue?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            Travel for weddings depend on the jounrney and the date. There is an extra charge for travel, but if you send me a message we can discuss more.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          What is included in makeup for 14yo?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            I include mascara, blusher and lip options of there choice.
          </p>
        </ng-template>
      </div>
    </div>
  </div>
</div>


<br>
<div class="centered">
  <h2 class="faq-heading Michelia">General Questions</h2> 
</div>
<div class="solid-line-break"></div>
<div ngbAccordion>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          What area are you located in?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            I am located in Hertfordshire, St Albans.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          Can you come to me?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            I am currently not offering an option for me to go to customers homes, however, that might change soon!
          </p>
        </ng-template>
      </div>
    </div>
  </div>


  <div ngbAccordionItem class="faq-item">
    <h3 ngbAccordionHeader>
      <button ngbAccordionButton class="question-box">
        <h4 class="DensiaSans question">
          How do I pay for a service?
        </h4>
      </button>
    </h3>
    <div ngbAccordionCollapse>
      <div ngbAccordionBody>
        <ng-template>
          <p class="answer DensiaSans">
            Payment is to be made on the day of your appointment, except for bridal services, which are to be paid in full 72 hours before the appiontment.
          </p>
        </ng-template>
      </div>
    </div>
  </div>

</div>
</div>